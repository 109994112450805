<template>
  <vx-table :columns="columns" :resource="resource" :filters="filters" />
</template>

<script>
import {
  VxTable,
  VxCellLink,
  VxCellDate,
  VxCellPlayerAvatar,
  VxCellMoney,
  VxCellImageWithTitle
} from '@/components/table'
import { activityTypesEnum, luckyRolls, passDataToResource } from '@/services/resources'
import { buildRoute, path } from '@/router'
import { filterTypes, sortOrders } from '@/services/table'
import { omit } from 'lodash'

export default {
  name: 'LuckyRollsWinners',

  components: {
    VxTable,
    VxCellDate,
    VxCellLink,
    VxCellMoney,
    VxCellImageWithTitle,
    VxCellPlayerAvatar
  },
  setup () {
    const resource = passDataToResource(luckyRolls.winners, {
      requestParams: {
        params: { include: 'player,luckyRollPrize.entity.file,luckyRoll,activity' }
      }
    })

    const columns = [
      { key: 'id' },
      {
        key: 'player',
        component: VxCellPlayerAvatar
      },
      {
        key: 'lucky_roll',
        component: VxCellLink,
        tdAttr: ({ title, id }) => {
          return {
            label: title,
            subLabel: `#${id}`,
            href: buildRoute(path.luckyRollEdit, { params: { id } })
          }
        }
      },
      {
        key: 'roll_value',
        sortable: true
      },
      {
        key: 'lucky_roll_prize.entity',
        label: 'Prize',
        sortable: true,
        component: VxCellImageWithTitle,
        tdAttr: (prize) => {
          const { file: { external_link }, id, title } = prize
          return {
            label: title,
            href: external_link,
            id: id,
            link: buildRoute(path.items, { query: { id } })
          }
        }
      },
      {
        key: 'bet_price',
        component: VxCellMoney
      },
      { key: 'activity_type' },
      {
        key: 'player_roll_id',
        label: 'PF Data',
        component: VxCellLink,
        tdAttr: (id) => ({
          label: `#${id}`,
          href: buildRoute(path.rolls, { query: { id } })
        })
      },
      {
        key: 'created_at',
        sortable: true,
        defaultSort: sortOrders.desc,
        component: VxCellDate
      }
    ]

    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'player_id',
        type: filterTypes.number
      },
      {
        key: 'lucky_roll_id',
        type: filterTypes.number
      },
      {
        key: 'lucky_roll_prize_id',
        label: 'Prize id',
        type: filterTypes.number
      },
      {
        key: 'roll_value',
        type: filterTypes.number
      },
      {
        key: 'activity_type',
        type: filterTypes.select,
        options: Object.values(omit(activityTypesEnum, 'all'))
      },
      {
        key: 'created_at',
        type: filterTypes.dateRangePicker,
        apiKeys: ['created_from', 'created_to']
      }
    ]

    return {
      resource,
      columns,
      filters
    }
  }
}
</script>
